import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { retrieveItem } from "../../helpers/functions";

const BASE_URL = "https://masmapi-uat.shakthiapps.com/graphql";
// const BASE_URL = "https://dev.vertace.org/masm/api/graphql";
// const BASE_URL = "http://192.168.61.168:5009/graphql";
// const BASE_URL = "http://192.168.19.168:5009/graphql";

const httpLink = createHttpLink({
  uri: BASE_URL,
});

const authLink = setContext(async (_, { headers }) => {
  const session_id = retrieveItem("session_id");

  return {
    headers: {
      ...headers,
      session_id: session_id ? session_id : "",
    },
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: defaultOptions,
});
const handleLogout = () => {
  // localStorage.clear();
  // window.location.replace("/tnwb");
};
export async function QueryRequest(query, variables, dispatch) {
  try {
    let response = await client.query({
      query,
      variables,
    });
    let isUnauthorized =
      JSON.stringify(response).indexOf("Not authorized") > -1;
    if (isUnauthorized && dispatch) {
      //  dispatch(logoutRequest("invalid"));
      handleLogout();
      return "Not authorized";
    }
    return response;
  } catch (e) {
    if (dispatch) {
      //  dispatch(logoutRequest("invalid"));
      handleLogout();
    }
    return { req_error: e };
  }
}
export async function MutateRequest(mutation, variables, dispatch) {
  let response = null;
  try {
    response = await client.mutate({
      mutation,
      variables,
    });
    let isUnauthorized =
      JSON.stringify(response).indexOf("Not authorized") > -1;
    if (isUnauthorized && dispatch) {
      //  dispatch(logoutRequest("invalid"));
      handleLogout();

      return "Not authorized";
    }
    return response;
  } catch (e) {
    if (dispatch) {
      //  dispatch(logoutRequest("invalid"));

      handleLogout();
    }
    return { req_error: e };
  }
}
