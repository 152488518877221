import React, { useEffect, useState } from "react";
import BookingAbishekamDate from "./booking_abishekam_date";
import SpotBookingMandram from "../../spot_booking/spot_booking_form/spot_booking_mandram_vsg";
import BookingTime from "./booking_time";
import { Card, Col, Form, Row, Space } from "antd";
import SpotBookingInchargeName from "../../spot_booking/spot_booking_form/spot_booking_incharge_name";
import SpotBookingInchargeMobileNo from "../../spot_booking/spot_booking_form/spot_booking_incharge_mobile_no";
import IrumudiCount from "./booking_irumudi_count";
import BookingModal from "../booking_detail_card";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../services/redux/selector";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  query_check_has_pos,
  query_get_mandram_confirmed_waiting_count_summary,
  query_get_mandram_list,
  query_get_season_date_slot_list,
} from "../../../../services/redux/slices";
import BookingLeaderDetailsFetch from "../booking_leader_fetch_details/booking_leader_details_fetch";
import LeaderDetailsBooking from "./booking_leader_details/booking_leader_details";
import BookingButton from "./booking_button";
import BookingPayment from "./booking_radio_button";
import { toast } from "react-toastify";
import BookingAmount from "./booking_amount";
import moment from "moment";
import { retrieveItem, storeItem } from "../../../../helpers/functions";
import { startCase } from "lodash";
import { useWatch } from "react-hook-form";

const BookingForm = (props) => {
  const {
    set_modal_open,
    set_booking_details,
    set_selected_time,
    selected_time,
    set_selected_item,
    selected_item,
    booking_details,
    set_mandram_id,
    initial_values,
    set_initial_values,
    set_payment_mode,
    payment_status,
    set_payment_status,
    set_pay_mode,
    pay_mode,
    set_waiting_conformation,
    count,
    set_count,
    form,
    value,
    set_value,
    set_amount,
    amount,
    get_leader_details,
    set_get_leader_details,
    mandram_id,
    set_new_user_mobile_no,
    set_leader_mobile_number,
    get_leader_mobile_no,
    selected_id_proof,
    set_selected_id_proof,
    set_leader_details,
    leader_details,
  } = props;
  console.log("selected_id_proof", selected_id_proof);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const role = retrieveItem("role");
  const [mandram_item, set_mandram_item] = useState({});
  const [new_user_details, set_new_user_details] = useState(true);
  const [quota_value, set_quota_value] = useState({});
  const [balance_quota, set_balance_quota] = useState(0);
  const [irumudi_count, set_irumudi_count] = useState(0);
  const [is_button_show, set_is_button_show] = useState(false);
  // const [has_pos, set_has_pos] = useState(false);
  const [time_slots, set_time_slots] = useState([]);
  let all_required_values = {};
  // console.log("has_pos", has_pos);
  const date = Form.useWatch("date", form);
  const mobile = Form.useWatch("mobile", form);
  const id_number = Form.useWatch("id_number", form);
  const id_proof = Form.useWatch("id_proof", form);
  const group_leader = Form.useWatch("group_leader", form);
  // const payment_mode = Form.useWatch("payment_mode", form);
  const irumudi_total_count = Form.useWatch("irumudi_count", form);

  // const result = useDynamicSelector("check_has_pos");

  const { items: season_date_slot_list_items, loading: loading } =
    useDynamicSelector("get_season_date_slot_list");

  const item = useDynamicSelector("get_booking_user_details");
  const { items: mandram_items } = useDynamicSelector("get_mandram_details");

  // const check_has_pos_query = () => {
  //   let key = [{ key: "check_has_pos", loading: true }];
  //   let query = query_check_has_pos;
  //   let variables = {};
  //   dispatch(dynamicRequest(key, query, variables));
  // };

  // useEffect(() => {
  //   check_has_pos_query();
  // }, []);

  // useEffect(() => {
  //   set_has_pos(result);
  // }, [result]);

  console.log("mandram_item", mandram_item);
  const [available_quota_for_booking, set_available_quota_for_booking] =
    useState(0);

  const { confirmed_count, waiting_count } = useDynamicSelector(
    "get_mandram_confirmed_waiting_count_summary"
  );

  console.log("selected_item", selected_item);

  useEffect(() => {
    let result = season_date_slot_list_items?.find(
      (x) => x.id === selected_item?.id
    );
    set_time_slots(result?.time_slots);
  }, [selected_item, season_date_slot_list_items]);
  useEffect(() => {
    let balance_quota =
      quota_value?.confirmed_quota - quota_value?.confirmed_count;
    set_balance_quota(balance_quota);
    let total_allowed_quota =
      quota_value?.confirmed_quota +
      quota_value?.waiting_quota -
      quota_value?.confirmed_count;
    set_available_quota_for_booking(total_allowed_quota);
  }, [quota_value, irumudi_count]);

  const get_season_date_slot_list = () => {
    let key = [{ key: "get_season_date_slot_list", loading: true }];
    let query = query_get_season_date_slot_list;
    let variables = {
      sort: {
        field: "date",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_mandram_list = () => {
    let key = [{ key: "get_mandram_details", loading: true }];
    let query = query_get_mandram_list;
    let variables = {
      sort: {
        field: "internal_code",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const mandram_confirmed_waiting_count_summary = () => {
    let key = [
      { key: "get_mandram_confirmed_waiting_count_summary", loading: true },
    ];
    let query = query_get_mandram_confirmed_waiting_count_summary;
    let variables = {
      filter: {
        mandram_id: mandram_id,
        date_slot_id: selected_item?.id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (get_leader_mobile_no) {
      form.setFieldsValue({
        mobile: get_leader_mobile_no,
      });
    }
  }, [get_leader_mobile_no]);

  useEffect(() => {
    if (mandram_id && selected_item) {
      mandram_confirmed_waiting_count_summary();
    }
  }, [mandram_id, selected_item]);

  useEffect(() => {
    get_season_date_slot_list();
    get_mandram_list();
    dispatch(dynamicClear("get_booking_user_details"));
    storeItem("leader_details", "");
  }, []);

  useEffect(() => {
    if (initial_values.code) {
      form.setFieldsValue({
        ...initial_values,
      });
    } else {
      form.resetFields();
    }
  }, [initial_values]);

  const handle_change_values = (val, value) => {
    form.setFieldsValue({
      ...value,
      time_slot: selected_time,
      amount:
        season_date_slot_list_items?.[0]?.season?.ticket_price *
        value?.irumudi_count,
    });

    set_amount(
      season_date_slot_list_items?.[0]?.season?.ticket_price *
        value?.irumudi_count
    );
    set_irumudi_count(value?.irumudi_count);
    set_payment_mode(value?.payment_mode);
    set_booking_details(value);
  };
  useEffect(() => {
    let filter = season_date_slot_list_items?.filter(
      (c_date) =>
        moment(new Date(c_date?.date)).format("DD/MM/YYYY") ===
        moment(new Date()).format("DD/MM/YYYY")
    );
    form.setFieldsValue({
      date: filter?.[0]?.id,
      id_proof: "Aadhaar Card",
    });

    set_selected_item({ id: filter?.[0]?.id, date: filter?.[0]?.date });
  }, [season_date_slot_list_items]);

  useEffect(() => {
    all_required_values = form.getFieldsValue();
    if (!date) {
      set_is_button_show(true);
    } else if (!selected_time) {
      set_is_button_show(true);
    } else if (!mandram_id) {
      set_is_button_show(true);
    } else if (!irumudi_total_count) {
      set_is_button_show(true);
    } else if (!item?.id && !mobile) {
      set_is_button_show(true);
    }
    //  else if (!payment_mode) {
    //   set_is_button_show(true);
    // }
    else if (!item?.id && !id_number) {
      set_is_button_show(true);
    } else if (!item?.id && !id_proof) {
      set_is_button_show(true);
    } else if (!item?.id && !group_leader) {
      set_is_button_show(true);
    } else {
      set_is_button_show(false);
    }
  }, [
    selected_item,
    selected_time,
    mandram_id,
    irumudi_total_count,
    date,
    group_leader,
    id_proof,
    id_number,
    // payment_mode,
    item,
  ]);

  const on_finish = (values) => {
    set_new_user_mobile_no(values?.mobile);

    if (!selected_item) {
      toast.error("Please select the date");
    } else if (!selected_time) {
      toast.error("Please select the time slot");
    } else if (value === 2 && !item?.id) {
      toast.error("Please select existing leader details");
    } else if (
      Number(available_quota_for_booking) >= Number(irumudi_count) &&
      Number(irumudi_count) > Number(balance_quota)
    ) {
      set_waiting_conformation(true);
    } else if (Number(irumudi_count) > Number(available_quota_for_booking)) {
      toast.error("Today's quota limit reached");
    } else if (Number(irumudi_count) <= Number(balance_quota)) {
      set_modal_open(true);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexWrap: "wrap-reverse",
        flexDirection: "row",
      }}
    >
      <div style={{ width: "60%", flexWrap: "wrap", flexDirection: "row" }}>
        <Form
          onFinish={on_finish}
          layout="vertical"
          form={form}
          onValuesChange={handle_change_values}
        >
          <BookingAbishekamDate
            season_date_slot_list_items={season_date_slot_list_items}
            set_selected_item={set_selected_item}
            form={form}
            set_selected_time={set_selected_time}
          />
          {selected_item?.id && (
            <div>
              <BookingTime
                items={time_slots}
                selected_date={selected_item?.date}
                set_selected_time={set_selected_time}
                selected_time={selected_time}
              />
            </div>
          )}
          <div>
            <SpotBookingMandram
              mandram_items={mandram_items}
              set_mandram_item={set_mandram_item}
              set_initial_values={set_initial_values}
              set_mandram_id={set_mandram_id}
            />

            <Row gutter={16}>
              <Col span={12}>
                <SpotBookingInchargeName />
              </Col>
              <Col span={12}>
                <SpotBookingInchargeMobileNo />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <IrumudiCount
                  count={count}
                  set_count={set_count}
                  set_amount={set_amount}
                />
              </Col>
              <Col span={12}>
                <BookingAmount amount={amount} />
              </Col>
            </Row>

            <LeaderDetailsBooking
              set_leader_details={set_leader_details}
              leader_details={leader_details}
              set_new_user_details={set_new_user_details}
              new_user_details={new_user_details}
              get_leader_details={get_leader_details}
              set_get_leader_details={set_get_leader_details}
              set_value={set_value}
              value={value}
              set_selected_id_proof={set_selected_id_proof}
              selected_id_proof={selected_id_proof}
              set_leader_mobile_number={set_leader_mobile_number}
              get_leader_mobile_no={get_leader_mobile_no}
              form={form}
              item={item}
            />

            {/* <BookingPayment
              payment_status={payment_status}
              set_payment_status={set_payment_status}
              pay_mode={pay_mode}
              set_pay_mode={set_pay_mode}
              form={form}
              // has_pos={has_pos}
            /> */}
          </div>
          <BookingButton is_button_show={is_button_show} title={t("book")} />
        </Form>
      </div>

      <div style={{ width: "40%", justifyContent: "center" }}>
        <BookingModal
          booking_details={booking_details}
          season_date_slot_list_items={season_date_slot_list_items}
          selected_item={selected_item}
          quota_value={quota_value}
          set_quota_value={set_quota_value}
          mandram_id={mandram_id}
        />
      </div>
    </div>
  );
};

export default BookingForm;
