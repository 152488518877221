import { Card, Col, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  query_get_booking_count,
  useDynamicSelector,
} from "../../../services/redux";
import { retrieveItem } from "../../../helpers/functions";
import { useDispatch } from "react-redux";

const BookingModal = ({
  season_date_slot_list_items,
  selected_item,
  set_quota_value,
  quota_value,
  mandram_id,
}) => {
  const { t } = useTranslation();
  let quota = {};
  const role = retrieveItem("role");
  const dispatch = useDispatch();
  useEffect(() => {
    quota = season_date_slot_list_items?.find(
      (x) => x.id === selected_item?.id
    );
    set_quota_value(quota);
  }, [selected_item]);

  const mandram_counts = useDynamicSelector(
    "get_mandram_confirmed_waiting_count_summary"
  );

  const district_user_mandram_counts = useDynamicSelector("get_booking_count");

  const get_booking_district_user_mandram_counts = () => {
    let key = [{ key: "get_booking_count", loading: true }];
    let query = query_get_booking_count;
    let variables = {
      filter: {
        date_slot_id: selected_item?.id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    // if (role === "District User") {
    get_booking_district_user_mandram_counts();
    // }
  }, [selected_item]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        position: "sticky",
        top: "10px",
      }}
    >
      <Card
        title="Booking Summary"
        style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)", width: "95%" }}
      >
        {role === "District User" ? (
          ""
        ) : (
          <>
            {/* <Typography style={{ marginBottom: "2px", fontSize: "15px" }}>
              {t("district")}
            </Typography> */}

            {/* <Row gutter={24}>
              <Col span={12}>
                <Card
                  style={{
                    height: "55%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#196701",
                    borderRadius: "6px",
                    width: "100%",
                  }}
                >
                  <Row gutter={24}>
                    <Typography style={{ color: "white" }}>
                      {t("booked")}
                    </Typography>
                    <Typography style={{ color: "white" }}>
                      {quota_value?.confirmed_count}
                    </Typography>
                  </Row>
                </Card>
              </Col>

              <Col span={12}>
                <Card
                  style={{
                    height: "55%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#EC9501",
                    borderRadius: "6px",
                    width: "100%",
                  }}
                >
                  <Row gutter={24}>
                    <Typography style={{ color: "white" }}>
                      {t("waiting")}
                    </Typography>
                    <Typography style={{ color: "white" }}>
                      {quota_value?.waiting_count}
                    </Typography>
                  </Row>
                </Card>
              </Col>
            </Row> */}
          </>
        )}

        {role === "District User" && (
          <div>
            <Typography style={{ marginBottom: "2px", fontSize: "15px" }}>
              {t("all_mandrams")}
            </Typography>

            <Row gutter={24}>
              <Col span={12}>
                <Card
                  style={{
                    height: "55%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#196701",
                    borderRadius: "6px",
                    width: "100%",
                  }}
                >
                  <Row gutter={24}>
                    <Typography style={{ color: "white" }}>
                      {t("booked")}
                    </Typography>
                    <Typography style={{ color: "white" }}>
                      {district_user_mandram_counts?.total_confirmed_count || 0}
                    </Typography>
                  </Row>
                </Card>
              </Col>

              <Col span={12}>
                <Card
                  style={{
                    height: "55%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#EC9501",
                    borderRadius: "6px",
                    width: "100%",
                  }}
                >
                  <Row gutter={24}>
                    <Typography style={{ color: "white" }}>
                      {t("waiting")}
                    </Typography>
                    <Typography style={{ color: "white" }}>
                      {mandram_counts?.total_waiting_count || 0}
                    </Typography>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        )}

        {mandram_id && (
          <div>
            <Typography style={{ marginBottom: "2px", fontSize: "15px" }}>
              {t("mandram")}
            </Typography>

            <Row gutter={24}>
              <Col span={12}>
                <Card
                  style={{
                    height: "55%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#196701",
                    borderRadius: "6px",
                    width: "100%",
                  }}
                >
                  <Row gutter={24}>
                    <Typography style={{ color: "white" }}>
                      {t("booked")}
                    </Typography>
                    <Typography style={{ color: "white" }}>
                      {mandram_counts?.confirmed_count || 0}
                    </Typography>
                  </Row>
                </Card>
              </Col>

              <Col span={12}>
                <Card
                  style={{
                    height: "55%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#EC9501",
                    borderRadius: "6px",
                    width: "100%",
                  }}
                >
                  <Row gutter={24}>
                    <Typography style={{ color: "white" }}>
                      {t("waiting")}
                    </Typography>
                    <Typography style={{ color: "white" }}>
                      {district_user_mandram_counts?.waiting_count || 0}
                    </Typography>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </Card>
    </div>
  );
};

export default BookingModal;
