import React from "react";
import { Form, InputNumber } from "antd";

const ArrivedCount = () => {
  return (
    <Form.Item
      label="Arrived Count"
      name="arrived_count"
      rules={[
        {
          required: true,
          message: "Please enter arrived count",
        },
        {
          type: "number",
          message: "Please enter a valid number",
        },
      ]}
    >
      <InputNumber style={{ width: "100%" }} placeholder="Arrived count" />
    </Form.Item>
  );
};

export default ArrivedCount;
