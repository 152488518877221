import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "antd";

import { useDispatch } from "react-redux";
import SeasonDateSlotWaitingCountFormItem from "./season_date_slot_waiting_count_form_item";
import SeasonDateSlotConfirmedCountFormItem from "./season_date_slot_confrimed_count_form_item";
import { toast } from "react-toastify";
import {
  dynamicClear,
  dynamicRequest,
  update_season_date_slots_mutation,
  useDynamicSelector,
} from "../../../../../../../services/redux";
import SeasonDateSlotConfirmedQuotaFormItem from "./season_availability_confirmed _quota_form_item";
import SeasonDateSlotWaitingQuotaFormItem from "./season_availability_waiting_quota_form_item";

const SeasonAvailabilityDateSlotsMainForm = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    header_type,
    set_edit_modal_visible,
    edit_values,
    get_one_season_query,
    active_date_slots,
  } = props;
  const [initial_values, set_initial_values] = useState({});
  const {
    status: update_date_slot_season_status,
    error: update_date_slot_season_error,
  } = useDynamicSelector("update_season_date_slot");

  useEffect(() => {
    if (edit_values.id) {
      form.setFieldsValue({
        ...edit_values,
      });
      set_initial_values({ ...edit_values });
    } else {
      form.resetFields();
    }
  }, [edit_values]);
  useEffect(() => {
    if (update_date_slot_season_status === "success") {
      toast.success("Date Slot Updated");
      set_edit_modal_visible(false);
      active_date_slots();
      get_one_season_query();
      dispatch(dynamicClear("update_season_date_slot"));
    } else if (update_date_slot_season_error?.message) {
      toast.error(update_date_slot_season_error.message);
      active_date_slots();
      get_one_season_query();
      dispatch(dynamicClear("update_season_date_slot"));
    }
  }, [update_date_slot_season_status, update_date_slot_season_error]);

  // const create_season = (values) => {
  //   let key = [{ key: "create_season", loading: true }];
  //   let query = create_season_mutation;
  //   let variable = {
  //     data: {
  //       ...values,
  //     },
  //   };

  //   dispatch(dynamicRequest(key, query, variable));
  // };

  const update_date_slots = (edit_id, values) => {
    let key = [{ key: "update_season_date_slot", loading: true }];
    let query = update_season_date_slots_mutation;
    let variable = {
      id: edit_id,
      data: {
        confirmed_quota: values.confirmed_quota,
        waiting_quota: values.waiting_quota,
        // confirmed_count: values.confirmed_count,
        // waiting_count: values.waiting_count,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const on_finish = (values) => {
    // const { start_date, end_date } = values;
    // const range = calculate_date_range(start_date, end_date);
    // set_date_range(range);
    // Close the modal or perform any other actions
    // set_edit_modal_visible(false);

    if (header_type === "add") {
      // create_season(values);
    } else if (header_type === "edit") {
      // debugger;
      let edit_id = edit_values?.id;
      update_date_slots(edit_id, values);
    }
  };

  return (
    <div>
      <Form
        id="booking_form"
        onFinish={on_finish}
        style={{ width: "100%" }}
        form={form}
        initialValues={initial_values}
      >
        <SeasonDateSlotConfirmedQuotaFormItem />
        <SeasonDateSlotWaitingQuotaFormItem />
        {/* <SeasonDateSlotConfirmedCountFormItem />
        <SeasonDateSlotWaitingCountFormItem /> */}
      </Form>
    </div>
  );
};

export default SeasonAvailabilityDateSlotsMainForm;
