import { gql } from "@apollo/client";

export const query_district_dashboard = gql`
  query {
    get_district_admin_dashboard_mandram_count_summary {
      items {
        mandram_id
        mandram_name
        mandram_code
        count {
          booked
          arrived
          excess
          yet_to_enroll
        }
      }
    }
  }
`;
