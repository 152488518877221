import { Button, Card, Col, Form, Input, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import BookingLeaderDetailsFetch from "../../booking/booking_leader_fetch_details/booking_leader_details_fetch";
import IdNumber from "../../booking/booking_form/booking_id_number";
import IdProof from "../../booking/booking_form/booking_id_proof";
import Address from "../../booking/booking_form/booking_address";
import GroupLeader from "../../booking/booking_form/booking_group_leader";
import { storeItem } from "../../../../helpers/functions";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  query_get_booking_user_details,
  useDynamicSelector,
} from "../../../../services/redux";
import { useTranslation } from "react-i18next";

const VIPLeaderDetails = (props) => {
  const {
    leader_details,
    set_leader_details,
    get_leader_details,
    set_get_leader_details,
    set_selected_id_proof,
    selected_id_proof,
    set_booking_details,
    form,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  console.log("get_leader_details", leader_details);

  const item = useDynamicSelector("get_booking_user_details");

  const [get_leader_mobile_no, set_leader_mobile_number] = useState();
  const [leader_value, set_leader_value] = useState();

  useEffect(() => {
    if (item?.id) {
      set_leader_details(false);
      form.setFieldsValue({
        group_leader: "",
        id_number: "",
        address: "",
      });
    }
  }, [item]);

  useEffect(() => {
    if (leader_details) dispatch(dynamicClear("get_booking_user_details"));
    storeItem("leader_details", "");
  }, [leader_details]);

  const get_booking_user_details = () => {
    let key = [{ key: "get_booking_user_details", loading: true }];
    let query = query_get_booking_user_details;
    let variables = {
      custom: {
        booking_user_reference: get_leader_details,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
    set_leader_mobile_number("");
  };

  const handle_click = () => {
    if (get_leader_mobile_no) {
      get_booking_user_details();
      set_leader_details(true);
    }
  };

  const onclick_click = () => {
    set_leader_details(true);
    form.setFieldsValue({
      mobile: "",
    });
    set_get_leader_details("");
    dispatch(dynamicClear("get_booking_user_details"));
  };

  const handle_change = (e) => {
    const input_value = e.target.value;
    const mobile_number_pattern = /^\d{10}$/;

    if (mobile_number_pattern.test(input_value)) {
      set_leader_mobile_number(input_value);
    }
    set_get_leader_details(e.target.value);
  };

  return (
    <div>
      <Card style={{ width: "100%" }}>
        <Row style={{ height: "50px", width: "100%" }}>
          <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
            {t("leader_detail")}
          </Typography>
        </Row>

        <Row gutter={24}>
          <Col span={11}>
            <Form.Item
              label={t("contact_number")}
              name="contact_number"
              rules={[
                {
                  required: true,
                  message: "Please input leader mobile number!",
                },
                // {
                //   pattern: /^[0-9]{10}$/,
                //   message: "Please enter a valid 10-digit mobile number .",
                // },
              ]}
            >
              <Input
                style={{
                  width: "100%",
                }}
                onChange={handle_change}
                value={leader_value}
                disabled={item?.id ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={1}>
            <Button
              style={{
                backgroundColor: item?.id ? "#DC7633 " : "#033E3E",
                color: "white",
                marginTop: "29px",
                border: "transparent",
                borderRadius: "5px",
              }}
              onClick={item?.id ? onclick_click : handle_click}
            >
              {item?.id ? t("change") : t("ok")}
            </Button>
          </Col>
        </Row>
        {leader_details && !item?.id && (
          <div>
            <Row gutter={24}>
              <Col span={12}>
                <GroupLeader />
              </Col>
              <Col span={12}>
                <Address />
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <IdProof set_selected_id_proof={set_selected_id_proof} />
              </Col>
              <Col span={12}>
                <IdNumber selected_id_proof={selected_id_proof} />
              </Col>
            </Row>
          </div>
        )}

        {item?.id && (
          <div style={{ marginTop: "20px" }}>
            <Card
              style={{
                marginBottom: "20px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
              }}
            >
              <BookingLeaderDetailsFetch item={item} />
            </Card>
          </div>
        )}
      </Card>
    </div>
  );
};

export default VIPLeaderDetails;
