import { gql } from "@apollo/client";
import * as XLSX from "xlsx";

export const API_URL = "https://masmapi-uat.shakthiapps.com/graphql";
export const PORT = 7878;

export const languages = ["en"];
export const ProjectName = "MASM";
export const defaultPageLimit = 999999999999;
export const defaultPageNumber = 1;
export const page_count = 12;
export const operator_matches_page_count = 1;
export const DEFAULT_COUNTRY_CODE = "IN";
export const DEFAULT_STATE_CODE = "TN";
export const IS_TABLE_SCROLLABLE = true;

export const astrology_charts = [
  { path: "D1", value: "Brith Chart" },
  { path: "D9", value: "Navamansha Chart" },
];

export const GOOGLE_API_KEY = "AIzaSyCVY8hRJ-X4VVYVuDECrRQynxwvuk6Kgow";
export const LOCATION_PROVIDER = "google";

export const ASTROLOGY_USER_ID = "620110";
export const ASTROLOGY_API_KEY = "cb343e1cba3ecc769520b97ea12cfdc9";
export const ASTROLOGY_URL =
  "https://json.astrologyapi.com/v1/horo_chart_image/";

export const ASTROLOGY_BASE_URL =
  "https://json.astrologyapi.com/v1/horo_chart_image/";

export const IMAGE_URL = "https://cdn.standardmatrimony.com/";

export const PROMO_URL = "https://stdmry.page.link/";

export const HOROSCOPE_URL = "https://horoscope.standardmatrimony.com/";

export const Data = [
  {
    name: "mano",
    gender: "m",
    dob: "1995-10-02T00:00:00.000Z",
    country_id: "IN",
    state_id: "TN",
    city_id: "chennai",
  },
];

export const fileQuery = gql`
  mutation {
    createHospitalPatientVisitDocumentUploadUrl {
      status
      url
      error {
        status_code
        message
      }
    }
  }
`;
export const generateExcelFile = ({ header, data, filename }) => {
  // Create a new workbook and worksheet
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(account_sheet_items);

  // Define column headers
  const headers = header;
  // [
  //   "Institution",
  //   "Financial Year",
  //   "Income",
  //   "Demand",
  //   "Collection",
  //   "Balance",
  // ];

  // Set column headers in the worksheet
  const headerRange = XLSX.utils.decode_range(worksheet["!ref"]);
  const numColumns = headers.length;
  headerRange.e.c = numColumns - 1; // Adjust the end column of the header range
  for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
    const headerCell = XLSX.utils.encode_cell({ r: 0, c: col });
    worksheet[headerCell] = { v: headers[col] }; // Create a new cell object
  }

  // Process the data rows
  for (let row = headerRange.s.r + 1; row <= headerRange.e.r; row++) {
    const rowData = account_sheet_items[row - 1]; // Adjust index since row 0 is for headers
    const institutionName = rowData.institution.name;
    const termName = rowData.term.name;
    const income = rowData.demand.total_income;
    const demand = rowData.demand.contribution_net_accessed_amount;

    // Set values in respective columns
    const institutionCell = XLSX.utils.encode_cell({ r: row, c: 0 });
    worksheet[institutionCell] = { v: institutionName }; // Create a new cell object

    const termCell = XLSX.utils.encode_cell({ r: row, c: 1 });
    worksheet[termCell] = { v: termName }; // Create a new cell object

    const incomeCell = XLSX.utils.encode_cell({ r: row, c: 2 });
    worksheet[incomeCell] = { v: income }; // Create a new cell object

    const demandCell = XLSX.utils.encode_cell({ r: row, c: 3 });
    worksheet[demandCell] = { v: demand }; // Create a new cell object

    // Calculate values for Collection and Balance columns
    const collection = rowData.demand.contribution_net_accessed_amount;
    const balance = calculateBalance(income, collection);

    const collectionCell = XLSX.utils.encode_cell({ r: row, c: 4 });
    worksheet[collectionCell] = { v: collection }; // Create a new cell object

    const balanceCell = XLSX.utils.encode_cell({ r: row, c: 5 });
    worksheet[balanceCell] = { v: balance }; // Create a new cell object
  }

  // Update the worksheet range
  worksheet["!ref"] = XLSX.utils.encode_range(headerRange.s, headerRange.e);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Generate the Excel file
  const excelFile = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "binary",
  });

  // Download the Excel file
  const fileName = "data.xlsx";
  const blob = new Blob([s2ab(excelFile)], {
    type: "application/octet-stream",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();
};
export const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};

export const SpecialCharacterRemove = (input) => {
  // Remove forward slashes and concatenate the remaining string
  const result = input?.replace(/\//g, "");
  return result;
};

export const countFormat = (value) => {
  if (typeof value === "number") return `${value?.toLocaleString("en-IN")}`;
  else return "0";
};
export const PageBackgroundColor = {
  // back_color: "#f4f4f5",
  back_color: "#e6e6f5",
};
