import gql from "graphql-tag";

export const query_get_bank_list = gql`
  query get_bank_list(
    $sort: sort_input
    $filter: get_bank_list_filter_input
    $pagination: pagination_input
  ) {
    get_bank_list(filter: $filter, sort: $sort, pagination: $pagination) {
      items {
        id
        name
        is_active
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_create_bank = gql`
  mutation create_bank($data: create_bank_input!) {
    create_bank(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_bank = gql`
  mutation update_bank($id: String!, $data: update_bank_input!) {
    update_bank(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_delete_bank = gql`
  mutation delete_bank($id: String!) {
    delete_bank(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const select_bank = gql`
  query get_bank_list {
    bank_list: get_bank_list {
      items {
        id
        name
      }
    }
  }
`;
