import React, { useEffect, useState } from "react";
import { Row, Table, Typography } from "antd";
import ActionButton from "../../common/custom_action_button";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../../services/redux";
import moment from "moment";
import { capitalize, upperCase } from "lodash";
import CustomSMSModal from "../../common/custom_sms_modal";
import { useDispatch } from "react-redux";
import { send_sms_to_mobile } from "../../../../services/redux/slices/graphql/graphql_sms";
import { toast } from "react-toastify";

const { Title } = Typography;

const BookingDetailsTable = (props) => {
  const { loading_booking_detail, selector_name } = props;

  const dispatch = useDispatch();

  const [table_data, set_tableData] = useState([]);
  const [sms_modal_visible, set_sms_modal_visible] = useState(false);

  const {
    id,
    booked_on,
    mandram,
    booking_status,
    booking_reference,
    count,
    booking_user,
    date_slot,
  } = useDynamicSelector(
    selector_name === "get_enroll_action" ? "" : "get_booking_receipt"
  );

  const {
    status: message_status,
    loading: message_loading,
    error: sms_error,
  } = useDynamicSelector("send_sms_to_number");

  const handle_print = (event) => {
    var receipt_form = document.getElementById("get_receipt_form");

    event.stopPropagation();
    const url = `/print-receipt/${booking_reference}/${booking_user?.mobile}`;
    const new_tab = window.open(url, "_blank", "width=850, height=900");
    if (new_tab) {
      new_tab.focus();
    }
    receipt_form.reset();
    dispatch(dynamicClear("get_booking_receipt"));
  };

  const send_sms_api = (mobile, booking_id) => {
    let key = [{ key: "send_sms_to_number", loading: true }];
    let query = send_sms_to_mobile;
    let variables = {
      data: {
        booking_id,
        mobile,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (message_status === "success") {
      toast.success("Message sent!");
      set_sms_modal_visible(false);
      dispatch(dynamicClear("send_sms_to_number"));
    } else if (sms_error?.message) {
      toast.error("Failed to send message");
      dispatch(dynamicClear("send_sms_to_number"));
    }
  }, [message_status, sms_error]);

  const api_data = [
    {
      key: "1",
      header: "Booked Date",
      value: moment(booked_on).format("DD-MMM-YYYY").toUpperCase(),
    },
    {
      key: "2",
      header: "Abishekam Date",
      value: moment(date_slot?.date).format("DD-MMM-YYYY").toUpperCase(),
    },
    {
      key: "3",
      header: "District",
      value: mandram?.district?.name,
    },
    {
      key: "4",
      header: "VSG",
      value: `${mandram?.name} / ${mandram?.code}`,
    },
    {
      key: "5",
      header: "Group Leader Name",
      value: booking_user?.name,
    },
    {
      key: "6",
      header: "Group Leader Mobile",
      value: booking_user?.mobile,
    },
    {
      key: "7",
      header: "Irumudi Count",
      value: count,
    },
    {
      key: "8",
      header: "Status",
      value: (
        <b
          style={{
            color:
              upperCase(booking_status) === "CANCELLED"
                ? "red"
                : upperCase(booking_status) === "CONFIRMED"
                ? "green"
                : "black",
            fontSize: "16px",
          }}
        >
          {capitalize(booking_status)}
        </b>
      ),
    },
  ];

  const handle_share = () => {
    set_sms_modal_visible(true);
  };

  useEffect(() => {
    set_tableData(api_data);
  }, []);

  const columns = [
    {
      dataIndex: "header",
      key: "header",
      title: "Header",
      width: "514px",
    },
    {
      dataIndex: "value",
      key: "value",
      title: "Value",
      width: "514px",
    },
  ];

  return (
    <>
      <Row
        style={{
          backgroundColor: "#eeeaea",
          width: "100%",
          margin: "8px 0px 5px 0px",
          borderRadius: "10px",
          flexDirection: "column",
        }}
      >
        <Title level={5} style={{ margin: "5px 0px 5px 4px" }}>
          {"Booking Details :"}
        </Title>
        <Table
          style={{
            marginTop: "2px",
            marginBottom: "15px",
            margin: "2px 5px 15px 5px",
          }}
          dataSource={table_data}
          columns={columns}
          bordered
          pagination={false}
          loading={loading_booking_detail}
          showHeader={false}
          width="100%"
        />
        <Row
          style={{
            width: "100%",
            alignContent: "flex-end",
            justifyContent: "flex-end",
            alignSelf: "flex-end",
            margin: "8px",
          }}
        >
          {booking_status === "cancelled" ? (
            ""
          ) : (
            <>
              <ActionButton
                name={"Print"}
                on_click={(e) => handle_print(e)}
                bg_color={"#179093"}
              />
              <ActionButton
                name={"Share"}
                on_click={(e) => handle_share(e)}
                bg_color={"#179093"}
              />
            </>
          )}
        </Row>
      </Row>

      <CustomSMSModal
        loading={message_loading}
        sms_api={send_sms_api}
        modal_visible={sms_modal_visible}
        set_modal_visible={set_sms_modal_visible}
        sms_booking_id={id}
      />
    </>
  );
};

export default BookingDetailsTable;
