import { gql } from "@apollo/client";

export const query_dashboard_list = gql`
  query {
    get_overall_dashboard {
      today_count
      yesterday_count
      total_count
    }
  }
`;
