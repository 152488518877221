import React, { useEffect, useState } from "react";
import { Skeleton } from "antd";
import moment from "moment-timezone";
import { useDynamicSelector } from "../../../../services/redux";

const CountDetailCard = (props) => {
  const { title, day_type, bg_color, items, loading } = props;

  const [counts, set_counts] = useState({});

  const { datetime } = useDynamicSelector("get_current_date_time");

  useEffect(() => {
    if (items?.length > 0) {
      set_counts(calculate_counts(items, day_type));
    }
  }, [items]);

  const calculate_counts = (irumudi_calendar_items, type) => {
    const today = moment(datetime).tz("Asia/Kolkata").format("DD-MM-YYYY");
    const yesterday = moment(datetime)
      .tz("Asia/Kolkata")
      .subtract(1, "day")
      .format("DD-MM-YYYY");
    const tomorrow = moment(datetime)
      .tz("Asia/Kolkata")
      .add(1, "day")
      .format("DD-MM-YYYY");
    const counts = {
      today: {
        booked: 0,
        arrived: 0,
        yet_to_enroll: 0,
        excess: 0,
        enrolled: 0,
      },
      yesterday: {
        booked: 0,
        arrived: 0,
        yet_to_enroll: 0,
        excess: 0,
        enrolled: 0,
      },
      tomorrow: {
        booked: 0,
        arrived: 0,
        yet_to_enroll: 0,
        excess: 0,
        enrolled: 0,
      },
    };
    // enrolled added in object
    irumudi_calendar_items?.forEach((item) => {
      const item_date = moment(item.date)
        .tz("Asia/Kolkata")
        .format("DD-MM-YYYY");
      const countType =
        item_date === today
          ? "today"
          : item_date === yesterday
          ? "yesterday"
          : item_date === tomorrow
          ? "tomorrow"
          : null;

      if (countType) {
        const booked = item.count.booked || 0;
        const arrived = item.count.arrived || 0;
        const yet_to_enroll = item.count.yet_to_enroll || 0;
        const excess = item.count.excess || 0;
        // const excess = 0;

        //new
        const enrolled = item.count.enrolled || 0;

        counts[countType].booked += booked;
        counts[countType].arrived += arrived;
        counts[countType].yet_to_enroll = yet_to_enroll < 0 ? 0 : yet_to_enroll;
        counts[countType].excess += excess;

        // new
        counts[countType].enrolled += enrolled;

        const difference = booked - arrived;

        // if (difference < 0) {
        //   counts[countType].excess += Math.abs(difference);
        // } else {
        //   counts[countType].excess += excess;
        // }
      }
    });
    return counts[type];
  };

  return (
    <div
      className="count-detail-card"
      style={{
        backgroundColor: bg_color,
      }}
    >
      <h5 className="title">{title}</h5>
      <div className="count-details">
        <div className="count-detail">
          <h5>{"Net Booked"}</h5>
          {loading && (
            <Skeleton.Button active={true} size={"small"} shape={"rounded"} />
          )}
          {loading === false ? (
            <h4>{counts ? counts?.booked?.toLocaleString("en-IN") : 0}</h4>
          ) : (
            ""
          )}
        </div>
        <div className="count-detail">
          <h5>{"Enrolled"}</h5>
          {loading && (
            <Skeleton.Button active={true} size={"small"} shape={"rounded"} />
          )}
          {loading === false ? (
            // <h4>{counts ? counts?.arrived?.toLocaleString("en-IN") : 0}</h4>
            <h4>{counts ? counts?.enrolled?.toLocaleString("en-IN") : 0}</h4>
          ) : (
            ""
          )}
        </div>
        <div className="count-detail">
          <h5>{"Yet to Enroll"}</h5>
          {loading && (
            <Skeleton.Button active={true} size={"small"} shape={"rounded"} />
          )}
          {loading === false ? (
            <h4>
              {counts ? counts?.yet_to_enroll?.toLocaleString("en-IN") : 0}
            </h4>
          ) : (
            ""
          )}
        </div>
        <div className="count-detail">
          <h5>{"Excess"}</h5>
          {loading && (
            <Skeleton.Button active={true} size={"small"} shape={"rounded"} />
          )}
          {loading === false ? (
            <h4>{counts ? counts?.excess?.toLocaleString("en-IN") : 0}</h4>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default CountDetailCard;
