import React, { useEffect, useState } from "react";
import EnrollmentListTable from "../components/enroll/enrollment_history/enrollment_list_table";
import { Button, Card, Col, Modal, Row } from "antd";
import CustomPageTitle from "../components/common/custom_page_title";
import {
  get_closure_summary_query,
  get_enroll_booking_list_query,
} from "../../services/redux/slices/graphql/graphql_enroll_booking";
import {
  close_enrolment_session_mutation,
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import PaymentHistoryTable from "../components/enroll/payment_history_for_enroll/payment_history_table";
import VipEnrollmentPaymentTable from "../components/vip_enrollment_list/vip_enrollment_payment_table";
import { IoMdClose } from "react-icons/io";
import PrintClosureDetails from "../components/enroll/print_closure/print_closure_details";
import { retrieveItem } from "../../helpers/functions";
import axios from "axios";
import { get_current_counter_query } from "../../services/redux/slices/graphql/graphql_counter_group";
import EnrolPrintContent from "../components/enroll/print_closure/enrol_print_content";
import Title from "antd/lib/typography/Title";

const VipEnrollmentList = () => {
  const dispatch = useDispatch();
  const [total_amount, set_total_amount] = useState(0);
  const [closure_modal_open, set_closure_modal_open] = useState(false);
  const [current_date_time, set_current_date_time] = useState("");
  const [counter_mac_address, set_counter_mac_address] = useState("");
  console.log("counter_mac_address", counter_mac_address);
  const system_user = retrieveItem("system_user");

  const {
    status: close_enrolment_session_status,
    loading: close_enrolment_session_loading,
  } = useDynamicSelector("close_enrolment_session");

  const { name: current_counter_name, id: current_counter_id } =
    useDynamicSelector("get_current_counter");

  useEffect(() => {
    if (close_enrolment_session_status === "success") {
      set_closure_modal_open(false);
      get_enroll_booking_list();
      get_closure_details();
      dispatch(dynamicClear("close_enrolment_session"));
    }
  }, [close_enrolment_session_status]);

  const get_enroll_booking_list = () => {
    let key = [{ key: "get_current_session_enrolment_list", loading: true }];
    let query = get_enroll_booking_list_query;
    let variables = null;
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_closure_details = () => {
    let key = [{ key: "get_closure_summary", loading: true }];
    let query = get_closure_summary_query;
    dispatch(dynamicRequest(key, query));
  };

  const close_enrollment_session_status = () => {
    let keys = [{ key: "close_enrolment_session", loading: true }];
    let query = close_enrolment_session_mutation;
    let variables = null;
    dispatch(dynamicRequest(keys, query, variables));
  };

  const get_current_counter_name = () => {
    let key = [{ key: "get_current_counter", loading: true }];
    let query = get_current_counter_query;
    let variables = {
      data: {
        system_reference_id: counter_mac_address,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (counter_mac_address) {
      get_current_counter_name();
    }
  }, [counter_mac_address]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://localhost:7878/my-address");
        set_counter_mac_address(response?.data);
        console.error("response", response);
      } catch (error) {
        console.error("Error fetching MAC address:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    get_enroll_booking_list();
  }, []);

  const on_click = () => {
    set_closure_modal_open(true);
    get_closure_details();
    const now = new Date();
    const formatted_date_time = now.toLocaleString();
    set_current_date_time(formatted_date_time);
  };

  const close_session = () => {
    close_enrollment_session_status();
  };

  const Print_details = () => {
    get_closure_details();
    const print_window = window.open("", "", "width=600,height=600");
    const html_content = `
    <html>
      <head>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 20px;
          }
          h3 {
            text-align: center;
            color: #333;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
          }
          th, td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${document.getElementById("print-details").innerHTML}
      </body>
    </html>
  `;

    print_window.document.write(html_content);
    print_window.document.close();

    setTimeout(() => {
      print_window.print();
      print_window.close();
    }, 1000);
  };

  return (
    <div width="100%">
      <CustomPageTitle title={"VIP Enrollment List"} />

      <Row
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "98%",
          marginBottom: "5px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Title level={4} style={{ marginLeft: "23px" }}>
            Enrolled By : {system_user}
          </Title>
          <Button
            style={{ backgroundColor: "#23a2d4", color: "#fff" }}
            onClick={on_click}
          >
            Print Closure
          </Button>
        </div>
      </Row>
      <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Card
          style={{
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
            width: "96%",
          }}
        >
          <Title
            level={4}
            style={{
              textAlign: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            You Counter is {current_counter_name}
          </Title>
          <EnrollmentListTable set_total_amount={set_total_amount} />
        </Card>
      </Row>
      <Row
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <Card
          style={{
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
            width: "96%",
          }}
        >
          <VipEnrollmentPaymentTable />
        </Card>
        <div style={{ display: "none" }} id="closure-details">
          <PrintClosureDetails
            get_closure_details={get_closure_details}
            system_user={system_user}
            current_date_time={current_date_time}
          />
        </div>
        <div style={{ display: "none" }} id="print-details">
          <EnrolPrintContent
            get_closure_details={get_closure_details}
            system_user={system_user}
            current_date_time={current_date_time}
          />
        </div>
        <Modal
          title="Close Session"
          style={{ width: "auto" }}
          open={closure_modal_open}
          onCancel={() => set_closure_modal_open(false)}
          closeIcon={
            <span style={{ marginTop: "2px" }}>
              <IoMdClose
                style={{
                  color: "white",
                  width: "28px",
                  margin: "15px 0px 0px 0px",
                }}
              />
            </span>
          }
          footer={[
            <Button
              style={{
                backgroundColor: "#ec9501",
                color: "white",
                border: "transparent",
              }}
              id="print-details"
              onClick={Print_details}
            >
              Print
            </Button>,
            <Button
              loading={close_enrolment_session_loading}
              style={{
                backgroundColor: "#f96451",
                color: "white",
                border: "transparent",
              }}
              onClick={close_session}
            >
              Close Session
            </Button>,
          ]}
        >
          <PrintClosureDetails
            get_closure_details={get_closure_details}
            system_user={system_user}
            current_date_time={current_date_time}
          />
        </Modal>
      </Row>
    </div>
  );
};

export default VipEnrollmentList;
