import { Col, Form, Row, Typography } from "antd";
import { Box } from "native-base";
import React, { useEffect, useState } from "react";
import BookingUsernameFormItem from "./booking_username_form_item";
import BookingPasswordFormItem from "./booking_portal_password_form_item";
import BookingLoginButton from "./booking_login_button";
import {
  dynamicClear,
  dynamicRequest,
  query_login,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { storeItem } from "@helpers/functions";
import { redirect_page, retrieveItem } from "../../../../../helpers/functions";
import { navigate } from "../../../../../helpers/functions";
import { ROUTES } from "../../../../routes/my_routes";

const IrumudiAndBookingPortalForm = () => {
  const dispatch = useDispatch();
  const {
    status: login_status,
    session_id,
    district,
    error,
    system_user,
    page_accesses,
    page_component_accesses,
  } = useDynamicSelector("login");

  const role = retrieveItem("role");

  useEffect(() => {
    if (error) {
      if (error.message === "not found") {
        toast.error("Please enter a valid username");
        dispatch(dynamicClear("login"));
      } else if (error.message === "invalid_credentials") {
        toast.error("Please enter a valid password");
        dispatch(dynamicClear("login"));
      }
    }
    if (login_status === "success") {
      storeItem("session_id", session_id);
      storeItem("role", system_user?.roles?.[0]?.name);
      storeItem("page_accesses", page_accesses);
      storeItem("system_user", system_user?.name);
      storeItem("district_name", district?.name);
      storeItem("district_id", district?.id);
      storeItem("system_user_mail_id", system_user?.email);
      console.log("page_accesses", page_accesses);

      storeItem("page_component_accesses", page_component_accesses);

      if (system_user?.roles?.[0]?.name === "Enrolment Officer") {
        redirect_page(ROUTES.ENROLL);
      }
      if (system_user?.roles?.[0]?.name === "Super Admin") {
        redirect_page(ROUTES.IRUMUDI_CALENDAR);
      }
      if (system_user?.roles?.[0]?.name === "District Admin") {
        redirect_page(ROUTES.IRUMUDI_CALENDAR);
      }
      if (system_user?.roles?.[0]?.name === "District User") {
        redirect_page(ROUTES.IRUMUDI_CALENDAR);
      }
      if (system_user?.roles?.[0]?.name === "Bank Enrolment Officer") {
        redirect_page(ROUTES.ENROLL);
      }
      if (system_user?.roles?.[0]?.name === "Bank Branch Enrolment Officer") {
        redirect_page(ROUTES.ENROLL);
      }
      if (system_user?.roles?.[0]?.name === "Dashboard User") {
        redirect_page(ROUTES.DASHBOARD);
      }
      if (system_user?.roles?.[0]?.name === "Helpdesk Officer") {
        redirect_page(ROUTES.RECEIPT);
      }
      if (system_user?.roles?.[0]?.name === "Mandram Admin") {
        redirect_page(ROUTES.IRUMUDI_CALENDAR);
      }
      if (system_user?.roles?.[0]?.name === "MASM Admin") {
        redirect_page(ROUTES.IRUMUDI_CALENDAR);
      }
      if (system_user?.roles?.[0]?.name === "MASM User") {
        redirect_page(ROUTES.IRUMUDI_CALENDAR);
      }
      if (system_user?.roles?.[0]?.name === "Spl Enrolment Officer") {
        redirect_page(ROUTES.ENROLL);
      }
      if (system_user?.roles?.[0]?.name === "VIP Enrolment Officer") {
        redirect_page(ROUTES.VIP_BOOKING);
      }
      if (system_user?.roles?.[0]?.name === "Enrolment Action User") {
        redirect_page(ROUTES.ENROLL_ACTION);
      }
    }
  }, [
    login_status,
    session_id,
    page_accesses,
    page_component_accesses,
    system_user,
    error,
  ]);

  const get_login = (values) => {
    let key = [{ key: "login", loading: true }];
    let query = query_login;
    let variables = {
      custom: {
        username: values.username,
        password: values.password,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_finish = (values) => {
    if (!navigator.onLine) {
      toast.error("Something went wrong please try again");
      return;
    }
    get_login(values);
  };

  const handle_click = () => {
    navigate(ROUTES.TERMS_OF_SERVICES);
  };
  const handle_privacy_policy = () => {
    navigate(ROUTES.PRIVACY_POLICY);
  };
  return (
    <>
      <div>
        <Form onFinish={handle_finish} id="login_form">
          <BookingUsernameFormItem />
          <BookingPasswordFormItem />
          <BookingLoginButton />
        </Form>
        <Row gutter={24}>
          <Col span={12} style={{ justifyContent: "flex-end" }}>
            <Typography
              style={{
                textDecoration: "underline",
                marginTop: "8px",
                cursor: "pointer",
              }}
              onClick={handle_privacy_policy}
            >
              Privacy Policy
            </Typography>
          </Col>
          <Col span={12}>
            <Typography
              style={{
                textDecoration: "underline",
                marginTop: "8px",
                cursor: "pointer",
              }}
              onClick={handle_click}
            >
              Terms and Conditions
            </Typography>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default IrumudiAndBookingPortalForm;
