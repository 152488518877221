import { Button, Card, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import ReceiptNumberInput from "./search_enroll_form_inputs/receipt_number_input";
import ReceiptDetails from "../receipt_details_for_enroll/receipt_details";
import {
  booking_details_query,
  dynamicClear,
  dynamicRequest,
  enrollment_active_season_query,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const SearchEnrollDetailsForm = (props) => {
  const {
    get_enroll_booking_list,
    receipt_input_ref,
    open_token_printer_modal,
    get_closure_details,
  } = props;

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [receipt_number, set_receipt_number] = useState("");
  const [enroll_form_values, set_enroll_form_values] = useState("");

  const { status: enroll_booking_status } =
    useDynamicSelector("enroll_booking");

  const { status: enrollment_status } = useDynamicSelector("enroll_booking");
  const { payment_status } = useDynamicSelector("enrollment_payment_check");
  const { error: get_booking_details_error } =
    useDynamicSelector("booking_details");
  console.log("get_booking_details_error", get_booking_details_error);

  const on_finish = (values) => {
    set_receipt_number(values?.booking_reference);
    get_booking_details(values?.booking_reference);
  };

  const handle_input_key_down = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const get_booking_details = (receipt_no) => {
    let key = [{ key: "booking_details", loading: true }];
    let query = booking_details_query;
    let variables = {
      custom: {
        booking_reference: receipt_no,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (get_booking_details_error?.message) {
      toast.error(get_booking_details_error?.message);
      set_receipt_number("");
      form.resetFields("");
      dispatch(dynamicClear("booking_details"));
    }
  }, [get_booking_details_error]);

  useEffect(() => {
    if (enrollment_status === "enrolled" || payment_status === "completed") {
      form.resetFields("");
    } else if (enrollment_status === "failure") {
      form.resetFields("");
      if (receipt_input_ref.current) {
        receipt_input_ref.current.focus();
      }
    }
  }, [enrollment_status, payment_status, receipt_input_ref]);

  return (
    <>
      <Form
        style={{
          alignItems: "center",
          marginLeft: "27%",
        }}
        onFinish={on_finish}
        form={form}
      >
        <Row style={{ display: "flex" }}>
          <ReceiptNumberInput
            onKeyDown={handle_input_key_down}
            receipt_input_ref={receipt_input_ref}
          />
          <Form.Item>
            <Button
              style={{
                backgroundColor: "#eab117",
                color: "white",
                border: "transparent",
                marginLeft: "10px",
              }}
              htmlType="submit"
            >
              {t("get")}
            </Button>
          </Form.Item>
        </Row>
      </Form>
      {get_booking_details_error === null && (
        <ReceiptDetails
          receipt_number={receipt_number}
          set_enroll_form_values={set_enroll_form_values}
          get_enroll_booking_list={get_enroll_booking_list}
          enroll_form_values={enroll_form_values}
          set_receipt_number={set_receipt_number}
          form={form}
          receipt_input_ref={receipt_input_ref}
          open_token_printer_modal={open_token_printer_modal}
          get_closure_details={get_closure_details}
        />
      )}
    </>
  );
};

export default SearchEnrollDetailsForm;
