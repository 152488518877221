import { Form, Select, Typography } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../services/redux";

const BookingHistoryMandram = ({ mandram_items, set_mandram_item, form }) => {
  const { t } = useTranslation();
  const { items: get_mandram_details } = useDynamicSelector(
    "get_mandram_details"
  );
  const district_id = Form.useWatch("district_id_list", form);
  const on_change = (value) => {
    set_mandram_item(value);
  };
  useEffect(() => {
    if (!district_id) {
      form.setFieldsValue({ mandram_id_list: [] });
    }
  }, [district_id]);
  return (
    <div>
      <Form.Item
        label={t("mandram_vsg")}
        name="mandram_id_list"
        style={{ width: "250px" }}
      >
        <Select
          className="mandram-placeholder"
          mode="multiple"
          style={{
            width: "100%",
          }}
          placeholder={<Typography> All Mandrams</Typography>}
          onChange={on_change}
          allowClear
          showSearch
          filterOption={(input, option) =>
            option?.props?.children
              ?.toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {district_id &&
            get_mandram_details?.map((mandram) => (
              <Select.Option key={mandram.id} value={mandram.id}>
                {`${mandram?.code}/${mandram?.name}`}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default BookingHistoryMandram;
