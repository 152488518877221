import { gql } from "@apollo/client";

export const query_session_wise_report = gql`
  query get_session_wise_report($filter: session_wise_report_filter_input) {
    get_session_wise_report(filter: $filter) {
      total_cash_amount
      total_card_amount
      total_dd_amount
      total_upi_amount
      total_upi_amount
      total_arrived_count
      total_prepaid_arrived_count
      session_start_time
      session_end_time
      user_name
    }
  }
`;

export const query_get_enrolment_session_list = gql`
query get_enrolment_session_list($filter: enrolment_session_list_filter_input) {
  get_enrolment_session_list(filter: $filter) {
    items {
      user_name
      enrolment_session_id
      enrolment_session_date
      enrolment_session_number
      enrolment_start_datetime
      enrolment_end_datetime
      enrolment_status
      total_devotees
      total_amount
      total_enrolments
      cash_enrolments
      cash_amount
      card_amount
      card_enrolments
      upi_amount
      upi_enrolments
      dd_enrolments
      dd_amount
      
    }
  }
}

`;

export const query_get_session_enrolment_list = gql`
  query get_session_enrolment_list(
    $filter: enrolment_session_list_filter_input
  ) {
    get_session_enrolment_list(filter: $filter) {
      items {
        booking_id
        booking {
          booking_reference
          count
          confirmed_count
          cancelled_count
          waiting_count
          status
        }
        counter {
          name
        }
        counter_id
        enrolled_on
        arrived_count
        status
        booking {
          count
        }
        enrolled_by {
          name
          mobile
        }
        payment {
          amount
          payment_mode
        }
      }
      pagination {
        total_count
        page_number
        page_limit
      }
    }
  }
`;

export const get_session_details_query=gql`
query get_enrolment_session_details(
  $filter: enrolment_session_details_filter_input
) {
  get_enrolment_session_details(filter: $filter) {
    items {
      receipt_number
      booking_number
      total_devotees
      payment_mode
      amount
      dd_reference
      status
    }
  }
}
`;
