import React, { useEffect } from "react";
import ArrivedCount from "./inputs/arrived_count";
import { Form } from "antd";
import {
  dynamicClear,
  dynamicRequest,
  mutation_update_enrolment,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { startCase } from "lodash";

const UpdateEnrolmentForm = ({
  set_update_modal_visible,
  record,
  get_all_enrollment_list,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { loading, status, error } = useDynamicSelector("update_enrolment");
  const on_finish = (values) => {
    update_enrolment(values);
  };

  const update_enrolment = ({ arrived_count }) => {
    let keys = [{ key: "update_enrolment", loading: true }];
    let query = mutation_update_enrolment;
    let variable = {
      id: record?.id,
      data: {
        arrived_count,
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  useEffect(() => {
    form.setFieldsValue({ arrived_count: record?.arrived_count });
  }, [record]);

  useEffect(() => {
    if (status === "success") {
      toast.success("Enrolment updated");
      dispatch(dynamicClear("update_enrolment"));
      set_update_modal_visible(false);
      get_all_enrollment_list();
    } else if (error?.message) {
      toast.error(startCase(error?.message));
      set_update_modal_visible(false);
      dispatch(dynamicClear("update_enrolment"));
    }
  }, [status, error]);
  return (
    <Form onFinish={on_finish} form={form} id="update_enrolment_form">
      <ArrivedCount />
    </Form>
  );
};

export default UpdateEnrolmentForm;
