import { Col, Typography } from "antd";
import React from "react";

const ReceiptDetailsCard = (props) => {
  const { title, sub_title, sub_value, symbol } = props;
  return (
    <Col>
      <Typography style={{ fontSize: "15px" }}>{title}</Typography>
      <div>
        <Typography style={{ fontWeight: "bold" }}>
          {symbol} {sub_title}
          <text style={{ fontWeight: "bold", marginLeft: "3px" }}>
            {sub_value}
          </text>
        </Typography>
      </div>
    </Col>
  );
};

export default ReceiptDetailsCard;
