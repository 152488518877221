import { Card, Col, Row, Space } from "antd";
import React from "react";
import CountDetailCard from "../../irumudi_calendar/detail_cards/count_detail_card";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../services/redux";

const DistrictDashboardCount = (props) => {
  const { booked, arrived, not_arrived } = props;
  const { t } = useTranslation();
  const { items: district_count_details, loading: district_count_loading } =
    useDynamicSelector("get_dashboard_calendar");

  return (
    <Card
      style={{
        width: "25%",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Col
        style={{
          height: "180px",
          width: "240px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Row
          style={{
            backgroundColor: "#275670",
            height: "50px",
            color: "white",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {t("overall_district")}
        </Row>

        <Row gutter={24} style={{ marginTop: "20px" }}>
          <Col span={3}></Col>
          <Col span={14}>
            <h3 style={{}}>Booked</h3>
          </Col>
          <h3 style={{}}>{booked}</h3>
        </Row>
        <Row gutter={24}>
          <Col span={3}></Col>
          <Col span={14}>
            <h3 style={{}}>Enrolled</h3>
          </Col>
          <h3 style={{}}>{arrived}</h3>
        </Row>

        <Row gutter={24}>
          <Col span={3}></Col>
          <Col span={14}>
            <h3 style={{}}>Yet To Enroll</h3>
          </Col>
          <h3 style={{}}>{not_arrived}</h3>
        </Row>
      </Col>
    </Card>
  );
};

export default DistrictDashboardCount;
