import { Button, Col, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import EnrollSpotBooking from "./enroll_spot_booking/enroll_spot_booking";
import { useTranslation } from "react-i18next";
import PrintClosureDetails from "./print_closure/print_closure_details";
import { IoMdClose } from "react-icons/io";
import { dynamicClear, useDynamicSelector } from "../../../services/redux";
import { useDispatch } from "react-redux";
import { retrieveItem } from "../../../helpers/functions";
import EnrolPrintContent from "./print_closure/enrol_print_content";

const EnrollButtons = (props) => {
  const {
    get_enroll_booking_list,
    open_token_printer_modal,
    system_user,
    receipt_input_ref,
    get_closure_details,
    close_enrollment_session_status,
    create_enrollment_session_status,
    open_session_modal,
    set_can_enroll,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = retrieveItem("role");
  const [spot_booking_open, set_spot_booking_open] = useState(false);
  const [current_date_time, set_current_date_time] = useState("");
  const [closure_modal_open, set_closure_modal_open] = useState(false);

  const {
    status: close_enrolment_session_status,
    loading: close_enrolment_session_loading,
  } = useDynamicSelector("close_enrolment_session");

  useEffect(() => {
    if (close_enrolment_session_status === "success") {
      set_closure_modal_open(false);
      set_can_enroll(false);
      get_enroll_booking_list();
      get_closure_details();
      open_session_modal();
      dispatch(dynamicClear("close_enrolment_session"));
    }
  }, [close_enrolment_session_status]);

  const open_spot_booking = () => {
    set_spot_booking_open(true);
  };

  const close_session_confirmation = () => {
    set_closure_modal_open(true);
    get_closure_details();
    const now = new Date();
    const formatted_date_time = now.toLocaleString();
    set_current_date_time(formatted_date_time);
  };

  const Print_details = () => {
    get_closure_details();
    set_closure_modal_open(false);
    const contentElement = document.getElementById("print-details");
    const print_window = window.open("", "", "width=600,height=600");
    const html_content = `
    <html>
      <head>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 20px;
          }
          h3 {
            text-align: center;
            color: #333;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
          }
          th, td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${document.getElementById("print-details").innerHTML}
      </body>
    </html>
  `;

    print_window.document.write(html_content);
    print_window.document.close();

    setTimeout(() => {
      print_window.print();
      print_window.close();
    }, 1000);
  };

  const close_session = () => {
    close_enrollment_session_status();
  };

  return (
    <Col>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {role === "Enrolment Officer" && (
          <Button
            style={{
              backgroundColor: "#90a800",
              color: "white",
              marginRight: "10px",
              border: "transparent",
            }}
            onClick={open_spot_booking}
          >
            {t("enroll_spot_booking_button")}
          </Button>
        )}

        <Button
          style={{
            backgroundColor: "#23a2d4",
            color: "white",
            border: "transparent",
          }}
          onClick={close_session_confirmation}
        >
          {t("print_closure_button")}
        </Button>
      </div>
      <EnrollSpotBooking
        spot_booking_open={spot_booking_open}
        set_spot_booking_open={set_spot_booking_open}
        get_enroll_booking_list={get_enroll_booking_list}
        open_token_printer_modal={open_token_printer_modal}
        receipt_input_ref={receipt_input_ref}
        get_closure_details={get_closure_details}
      />
      <div style={{ display: "none" }} id="closure-details">
        <PrintClosureDetails
          get_closure_details={get_closure_details}
          system_user={system_user}
          current_date_time={current_date_time}
        />
      </div>
      <div style={{ display: "none" }} id="print-details">
        <EnrolPrintContent
          get_closure_details={get_closure_details}
          system_user={system_user}
          current_date_time={current_date_time}
        />
      </div>
      <Modal
        title="Close Session"
        style={{ width: "auto" }}
        open={closure_modal_open}
        onCancel={() => set_closure_modal_open(false)}
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        footer={[
          <Button
            style={{
              backgroundColor: "#ec9501",
              color: "white",
              border: "transparent",
            }}
            id="print-details"
            onClick={Print_details}
          >
            Print
          </Button>,
          <Button
            loading={close_enrolment_session_loading}
            style={{
              backgroundColor: "#f96451",
              color: "white",
              border: "transparent",
            }}
            onClick={close_session}
          >
            Close Session
          </Button>,
        ]}
      >
        <PrintClosureDetails
          get_closure_details={get_closure_details}
          system_user={system_user}
          current_date_time={current_date_time}
        />
      </Modal>
    </Col>
  );
};

export default EnrollButtons;
