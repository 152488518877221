import { Card, Col, Row, Space, Table } from "antd";
import React, { useEffect } from "react";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import DistrictDashboardCount from "../components/district_dashboard/district_dashbord_count/district_dashbord_count";
import MandramWiseCount from "../components/district_dashboard/mandram_wise_count/mandram_wise_count";
import { object } from "prop-types";
import { query_calendar_dashboard } from "../../services/redux/slices/graphql/graphql_irumudi_calendar";
import {
  dynamicRequest,
  query_district_dashboard,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import CustomBreadCrumbs from "../components/common/custom_breadcrumbs";

const DistrictDashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { items: mandram_count, loading: mandram_count_loading } =
    useDynamicSelector("get_district_admin_dashboard_mandram_count_summary");

  const calculateTotals = () => {
    let totalBooked = 0;
    let totalArrived = 0;
    let totalNotArrived = 0;

    mandram_count?.forEach((mandram) => {
      totalBooked += mandram.count.booked;
      totalArrived += mandram.count.arrived;
      totalNotArrived += mandram.count.yet_to_enroll;
    });

    return { totalBooked, totalArrived, totalNotArrived };
  };

  const { totalBooked, totalArrived, totalNotArrived } = calculateTotals();

  // const get_district_dashboard = () => {
  //   let keys = [{ key: "get_dashboard_calendar", loading: true }];
  //   let query = query_calendar_dashboard;
  //   let variable = {};
  //   dispatch(dynamicRequest(keys, query, variable));
  // };

  const get_district_wise_mandram_dashboard = () => {
    let keys = [
      {
        key: "get_district_admin_dashboard_mandram_count_summary",
        loading: true,
      },
    ];
    let query = query_district_dashboard;
    let variable = {};
    dispatch(dynamicRequest(keys, query, variable));
  };

  // useEffect(() => {
  //   get_district_dashboard();
  // }, []);

  useEffect(() => {
    get_district_wise_mandram_dashboard();
  }, []);

  return (
    <Col style={{ width: "100%", height: "auto" }}>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row>
          <CustomPageTitle title={t("district_dashboard_title")} />
        </Row>
        {/* <Row style={{ margin: "8px", marginRight: "23px" }}>
          <CustomBreadCrumbs pages={"Home/District-dashboard"} />
        </Row> */}
      </Row>
      <Col
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Card
          style={{
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            overflowY: "hidden",
            width: "70%",
          }}
        >
          <MandramWiseCount />
        </Card>
        <DistrictDashboardCount
          booked={totalBooked}
          arrived={totalArrived}
          not_arrived={totalNotArrived}
        />
      </Col>
    </Col>
  );
};

export default DistrictDashboard;
