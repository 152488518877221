import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  dynamicRequest,
  enrolment_user_list,
  query_get_masm_user_list,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import {
  get_session_details_query,
  query_get_enrolment_session_list,
  query_get_session_enrolment_list,
  query_session_wise_report,
} from "../../../../services/redux/slices/graphql/graphql_session_wise_report";
import { ROUTES } from "../../../routes/my_routes";
import { navigateWithProps, retrieveItem } from "../../../../helpers/functions";
import CustomPageTitle from "../../common/custom_page_title";
import { FaPrint } from "react-icons/fa";
import { GrFormView } from "react-icons/gr";
import EnrolPrintContent from "../../enroll/print_closure/enrol_print_content";
import EnrolPrint from "../entrollment_pdf";
import moment from "moment";
import { IoMdInformationCircleOutline } from "react-icons/io";
import EnrolmentSessionHistory from "./enrolment_session_history_details";
import SakthiPeedamFromDate from "../../system_report/sakthi_peedam_report/sakthi_peedam_report_form/sakthi_peedam_report_from_date";
import SakthiPeedamToDate from "../../system_report/sakthi_peedam_report/sakthi_peedam_report_form/sakthi_peedam_report_to_date";
import jsPDF from "jspdf";
import _orderBy from "lodash/orderBy";
import { season_date_slots_list } from "../../../../services/redux/slices/graphql/graphql_daily_transaction_report";

const EnrolmentSessionList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const role = retrieveItem("role");
  const [current_page_limit, set_current_page_limit] = useState(1);
  const [current_page_number, set_current_page_number] = useState(10);
  const [user_id, set_user_id] = useState("");
  const [print, set_print] = useState(false);
  const [list_value, set_list_value] = useState({});
  const [pdf_modal, set_pdf_modal] = useState(false);
  const [date_value, set_date_value] = useState("");
  const [date, set_date] = useState();

  const [enrolment_history_modal, set_enrolment_history_modal] =
    useState(false);
  const { items: session_enrolment_list } = useDynamicSelector(
    "get_session_enrolment_list"
  );

  const { RangePicker } = DatePicker;
  const handle_history = (record) => {
    // navigateWithProps(
    //   `${ROUTES.ENROLMENT_SESSION_HISTORY_DETAILS}/${record?.id}`,
    //   { name: record?.name }
    // );
    set_list_value(record);
    get_session_details(record?.enrolment_session_id);
    set_enrolment_history_modal(true);
  };

  const { items: system_users, loading: masm_user_loading } =
    useDynamicSelector("get_enrolment_user");
  const { items: search_date_list } = useDynamicSelector(
    "get_season_date_slot_list"
  );
  const date_slot_list = _orderBy(search_date_list, ["date"], ["asc"]);
  const { items: enrolment_session_list, loading } = useDynamicSelector(
    "get_enrolment_session_list"
  );
  console.log("enrolment_session_list", enrolment_session_list);

  const { items: enrol_session_details } = useDynamicSelector(
    "get_enrolment_session_details"
  );

  const handle_print = (record) => {
    get_session_wise_report(record?.enrolment_session_id);
    set_list_value(record);
    set_pdf_modal(true);
    const current_date = moment().format("DD/MM/YYYY hh:mm A");
    set_date(current_date);
  };
  let column = [
    {
      title: "Reference id",
      key: "session_reference_id",
      render: (record) => {
        const date = record?.enrolment_session_date;
        const session = record?.enrolment_session_number;
        return `S-${date}-${session}`;
      },
    },
    {
      title: "User",
      dataIndex: "user_name",
      width: "120px",
    },
    {
      title: "Total Enrollments",
      dataIndex: "total_enrolments",
      key: "session_reference_id",
      width: "120px",
    },
    {
      title: "Cash Amount",
      dataIndex: "cash_amount",
      key: "session_reference_id",
    },
    {
      title: "DD Amount",
      dataIndex: "dd_amount",
      key: "session_reference_id",
    },
    {
      title: "DD Received",
      dataIndex: "dd_enrolments",
      key: "session_reference_id",
    },
    {
      title: "UPI Amount",
      dataIndex: "upi_amount",
      key: "session_reference_id",
    },
    {
      title: "Card Amount",
      dataIndex: "card_amount",
      key: "session_reference_id",
    },

    {
      title: "Start time",
      dataIndex: "enrolment_start_datetime",
      key: "start_time",
      render: (record) =>
        record ? moment(record).format("DD-MM-YYYY hh:mm:ss A") : "-",
    },
    {
      title: "End Time",
      dataIndex: "enrolment_end_datetime",
      key: "end_time",
      render: (record) =>
        record ? moment(record).format("DD-MM-YYYY hh:mm:ss A") : "-",
    },
    {
      title: "Status",
      key: "enrolment_status",
      fixed: "right",
      render: (record) => {
        const status = record?.enrolment_status;
        return status === "closed" ? "Closed" : "-";
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      fixed: "right",
      render: (record) => (
        <>
          <Space
            style={{
              width: "50px",
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            {(role === "Super Admin" || role === "MASM Admin") && (
              <Tooltip title="Info">
                <IoMdInformationCircleOutline
                  size={29}
                  onClick={() => {
                    handle_history(record);
                  }}
                  color="blue"
                />
              </Tooltip>
            )}

            <Tooltip title="view">
              <GrFormView
                size={30}
                onClick={() => {
                  handle_print(record);
                }}
                color="blue"
              />
            </Tooltip>
          </Space>
        </>
      ),
    },
  ];
  const get_all_masm_user_list = () => {
    let key = [{ key: "get_enrolment_user", loading: true }];
    let query = enrolment_user_list;
    let variables = {
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const get_session_wise_report = (session_id) => {
    let key = [{ key: "get_session_wise_report", loading: true }];
    let query = query_session_wise_report;
    let variables = {
      filter: {
        session_id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_date_list = () => {
    let key = [{ key: "get_season_date_slot_list", loading: true }];
    let query = season_date_slots_list;
    dispatch(dynamicRequest(key, query));
  };
  const get_session_wise_list = (filter, pagination) => {
    let key = [{ key: "get_enrolment_session_list", loading: true }];
    let query = query_get_enrolment_session_list;
    let variables = {
      filter: {
        user_id: user_id,
        date: date_value,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_change = (val, values) => {
    set_user_id(values?.key);
  };

  useEffect(() => {
    get_session_wise_list();
  }, [date_value]);

  useEffect(() => {
    get_all_masm_user_list();
  }, []);

  useEffect(() => {
    get_date_list();
  }, []);

  useEffect(() => {
    get_session_wise_list(user_id, {
      page_limit: current_page_limit,
      page_number: current_page_number,
    });
  }, [current_page_limit, current_page_number, user_id]);

  const fetch_data = (page_number, page_limit) => {
    set_current_page_limit(page_limit);
    set_current_page_number(page_number);
  };
  const handle_close = () => {
    set_pdf_modal(false);
    set_list_value({});
    set_enrolment_history_modal(false);
  };

  const get_session_wise_history_list = () => {
    let key = [{ key: "get_session_enrolment_list", loading: true }];
    let query = query_get_session_enrolment_list;
    let variables = {
      filter: {
        user_id: list_value?.id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_session_details = (session_id) => {
    let key = [{ key: "get_enrolment_session_details", loading: true }];
    let query = get_session_details_query;
    let variables = {
      filter: {
        enrolment_session_id: session_id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_session_wise_history_list();
  }, [list_value?.id]);

  const generatePDF = () => {
    const doc = new jsPDF();

    const columns = [
      "Enrollment Receipt Number",
      "Booking number",
      "Total Devotees",
      "Amount",
      "Payment mode",
      "DD Reference Number",
      // "Status",
    ];

    doc.text("Booking Information", 14, 10);

    const jsonData = [...enrol_session_details];

    // Convert JSON data to an array of arrays
    const data = jsonData?.map((booking) => [
      booking?.receipt_number || "-",
      booking?.booking_number || "-",
      booking?.total_devotees || "-",
      booking?.amount || "-",
      booking?.payment_mode || "-",
      booking?.dd_reference || "-",
      // booking?.status || "-",
    ]);

    // Use jspdf-autotable to generate the table
    doc.autoTable({
      head: [columns],
      body: data,
      startY: 20,
      theme: "grid",
      headStyles: {
        fillColor: [124, 66, 62], // Black header color
        textColor: [255, 255, 255],
      }, // you can customize the theme
      styles: {
        lineColor: [0, 0, 0], // Border color for the rest of the table
      },
    });

    doc.save("bookings_history.pdf");
    set_print(true);
  };

  const custom_filter_option = (input, option) => {
    const input_value = input.toLowerCase();
    const option_label = option.props.children.toLowerCase();
    return option_label.indexOf(input_value) === 0;
  };

  const on_change = (value) => {
    set_date_value(value);
  };

  return (
    <>
      <CustomPageTitle title={"Enrolment Sessions"} />
      <div style={{ padding: "20px" }}>
        <Form
          layout="vertical"
          style={{ display: "flex", flexDirection: "row", gap: "20px" }}
          // onValuesChange={handle_change}
        >
          {(role === "Super Admin" || role === "MASM Admin") && (
            <Form.Item label="Users" name="user_id" style={{ width: "300px" }}>
              <Select
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select"
                showSearch
                filterOption={custom_filter_option}
                onChange={handle_change}
              >
                {system_users?.map((option) => (
                  <Select.Option
                    key={option?.system_user?.id}
                    value={option?.system_user?.id}
                  >
                    {option?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item label="Date" name="date" style={{ width: "300px" }}>
            <Select
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Please select"
              onChange={on_change}
            >
              {date_slot_list?.map((option) => (
                <Select.Option key={option?.date} value={option?.date_string}>
                  {option?.date_string}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/* <Col span={5}>
              <SakthiPeedamFromDate />
            </Col> */}
          {/* <Col span={5}>
              <SakthiPeedamToDate />
            </Col> */}
          {/* <Col span={5} style={{ marginTop: "30px" }}>
            <Button htmlType="submit" type="primary">
              Search
            </Button>
          </Col> */}
        </Form>

        <Table
          columns={column}
          dataSource={enrolment_session_list}
          loading={loading}
          scroll={{
            x: 1900,
          }}
        />
        {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
          <Pagination
            // total={}
            showSizeChanger={true}
            current={current_page_number}
            hideOnSinglePage={false}
            onChange={fetch_data}
            style={{ marginTop: "10px" }}
          />
        </div> */}
        <Modal
          open={pdf_modal}
          onCancel={handle_close}
          footer={null}
          title="Enrolment receipt"
          width={500}
        >
          <EnrolPrint
            list_value={list_value}
            onCancel={handle_close}
            date={date}
            set_pdf_modal={set_pdf_modal}
          />
        </Modal>
        <Modal
          open={enrolment_history_modal}
          onCancel={handle_close}
          footer={false}
          title="Enrolment Session History"
          width={"70%"}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={generatePDF}
              style={{
                backgroundColor: "#550a05c4",
                color: "white",
                marginRight: "10px",
              }}
            >
              Download
            </Button>
            <Button onClick={handle_close} danger>
              Close
            </Button>
          </div>
          <EnrolmentSessionHistory
            print={print}
            set_print={set_print}
            list_value={list_value}
            onCancel={handle_close}
          />
        </Modal>
      </div>
    </>
  );
};

export default EnrolmentSessionList;
